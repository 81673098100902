<template lang="pug">
.today-heatmaps-container
  .all-heatmaps(v-if="allHeatMapsData.length")
    today-heatmap(v-for="heatmapData in visibleHeatmaps"
                :key="heatmapData.id"
                :heatmap-data="heatmapData"
                :selected-options="selectedOptions")
    infinite-loading(:identifier="allHeatMapsData"
                    :distance="10"
                    @infinite="renderMoreHeatmaps")
      template(v-slot:complete)
        span
  .no-selection(v-else)
    span(v-if="!isLoading") {{ $t('pulse_tab.today_tab.no_options') }}
</template>

<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import times from 'lodash/times';
import merge from 'lodash/merge';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import moment from 'moment';
import TodayHeatmap from './TodayHeatmap.vue';

const HOURS_PER_DAY = 24;

export default {
  components: {
    TodayHeatmap,
  },
  props: {
    metrics: {
      type: Object,
      required: true,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    selectedOptions: {
      type: Array,
      default: () => [],
    },
    heatMapsInitialConfg: {
      type: Object,
    },
  },
  data() {
    return {
      allHeatMapsData: [],
      visibleHeatmaps: [],
      lastVisibleIndex: -1,
    };
  },
  methods: {
    formatterBasedOnCategory(data) {
      return this.getFormatterForMetricCategory(get(data, 'metric_category', ''));
    },
    getFormatterForMetricCategory(category) {
      switch (category) {
        case 'percent':
          return (v) => `${Math.round(v * 100).toFixed(2)}%`; // eslint-disable-line
        case 'timedelta':
          return this.$aiq.formatters.timeDelta;
        case 'interval':
          return this.$aiq.formatters.timeDelta;
        case 'timedeltaWithEmptyDefault':
          return (v) => (!isNumber(v) ? '' : this.$aiq.formatters.timeDelta(v));
        default:
          return (v) => v;
      }
    },
    prepareChartData() {
      const metricsDataset = get(this.metrics, 'data.dataset', []);
      const allHeatMapsData = this.heatMapsInitialConfg.map(configItem => {
        const heatMapData = metricsDataset.find(m => m.metric === configItem.responseDataKey);
        const { selectedOptionsDataPoints, leftAggregate, rightAggregate, tooltipsConfig } =
          this.getSelectedOptionsDataPoints(heatMapData, configItem);
        const includeAggregates = !(isNil(leftAggregate) || isNil(rightAggregate));

        const chartData = {
          id: configItem.id,
          header: {
            title: configItem.title,
            titleTooltip: configItem.titleTooltip,
          },
          preLoadedchartData: {
            dataset: [{
              data_points: selectedOptionsDataPoints,
              metric: configItem.chartConfig.metric,
            }],
          },
          config: configItem.chartConfig,
          colors: configItem.colors,
          tooltipsConfig,
          tooltipsClassName: configItem.tooltipsClassName,
        };

        if (includeAggregates) {
          chartData.header = {
            ...chartData.header,
            left: {
              label: leftAggregate.label,
              value: leftAggregate.value,
            },
            right: {
              label: rightAggregate.label,
              value: rightAggregate.value,
            },
          };
        }

        return chartData;
      });
      this.allHeatMapsData = allHeatMapsData;
    },
    generateEmptyDataPoints(
      y,
      cellValueKey,
      leftAggregateKey,
      rightAggregateKey,
      tooltipsKeys,
    ) {
      const currentHour = moment().hours();

      return times(HOURS_PER_DAY, (hour) => {
        const initialValue = hour <= currentHour ? 0 : null;

        for (const key in tooltipsKeys) {
          if (Object.hasOwn(tooltipsKeys, key)) {
            tooltipsKeys[key] = initialValue;
          }
        }

        return {
          hour,
          [cellValueKey]: initialValue,
          ...(leftAggregateKey ? { [leftAggregateKey]: initialValue } : {}),
          ...(rightAggregateKey ? { [rightAggregateKey]: initialValue } : {}),
          ...tooltipsKeys,
          ...y,
        };
      });
    },
    getSelectedOptionsDataPoints(heatMapData, configItem) {
      const chartMetricName = configItem.chartConfig.metric;
      const cellValueKey = configItem.chartConfig.lines[chartMetricName].valueKey;
      const yKey = configItem.chartConfig.lines[chartMetricName].yKey;
      const leftAggregateConfig = cloneDeep(configItem.leftAggregate);
      const rightAggregateConfig = cloneDeep(configItem.rightAggregate);
      const includeAggregates = !(isNil(leftAggregateConfig) || isNil(rightAggregateConfig));
      const tooltipsConfig = cloneDeep(configItem.tooltips);

      let selectedOptionsDataPoints = [];

      this.selectedOptions.forEach((option, idx) => {
        const skeletonDatapoints = this.generateEmptyDataPoints(
          { [yKey]: idx },
          cellValueKey,
          get(leftAggregateConfig, 'valueKey'),
          get(rightAggregateConfig, 'valueKey'),
          tooltipsConfig.reduce(
            (accumulator, currentValue) => ({
              ...accumulator,
              [currentValue.valueKey]: 0,
            }),
            {},
          ),
        );

        const optionHeatMapData = heatMapData.dataset.filter(ds => ds.label === option.name);
        if (optionHeatMapData.length) {
          for (const dataPoint of optionHeatMapData[0].data_points) {
            const numOfHours = this.calculateNumOfHoursToday(dataPoint);

            if (skeletonDatapoints[numOfHours]) {
              const chartDataPoint = dataPoint.ys.reduce((accumulator, yObj) => {
                const dp = { ...accumulator };

                // cell data
                if (yObj.metric === cellValueKey) {
                  dp[yObj.metric] = Number(yObj.value);
                }

                // left aggregate data
                if (includeAggregates && yObj.metric === leftAggregateConfig.valueKey) {
                  dp[yObj.metric] = Number(yObj.value);
                  leftAggregateConfig.callFormatter = this.formatterBasedOnCategory(yObj);
                }

                // right aggregate data
                if (includeAggregates && yObj.metric === rightAggregateConfig.valueKey) {
                  dp[yObj.metric] = Number(yObj.value);
                  rightAggregateConfig.callFormatter = this.formatterBasedOnCategory(yObj);
                }

                // tooltips data
                const tooltipItem = tooltipsConfig.find(t => yObj.metric === t.valueKey);
                if (tooltipItem) {
                  dp[yObj.metric] = Number(yObj.value);
                  const categoryContainer = yObj.metric_category
                    ? yObj
                    : { metric_category: tooltipItem.defaultMetricCategory };
                  tooltipItem.callFormatter = this.formatterBasedOnCategory(categoryContainer);
                }
                return dp;
              }, {});

              merge(skeletonDatapoints[numOfHours], chartDataPoint);
            }
          }
        } else {
          if (includeAggregates) {
            leftAggregateConfig.callFormatter =
            this.getFormatterForMetricCategory(leftAggregateConfig.defaultMetricCategory);

            rightAggregateConfig.callFormatter =
            this.getFormatterForMetricCategory(rightAggregateConfig.defaultMetricCategory);
          }

          tooltipsConfig.forEach(t => {
            t.callFormatter = this.getFormatterForMetricCategory(t.defaultMetricCategory);
          });
        }
        selectedOptionsDataPoints = selectedOptionsDataPoints.concat(skeletonDatapoints);
      });

      const chartDataPoints = { selectedOptionsDataPoints, tooltipsConfig };

      if (includeAggregates) {
        chartDataPoints.leftAggregate =
          this.getHeaderElements(selectedOptionsDataPoints, leftAggregateConfig);
        chartDataPoints.rightAggregate =
          this.getHeaderElements(selectedOptionsDataPoints, rightAggregateConfig);
      }

      return chartDataPoints;
    },
    calculateNumOfHoursToday(dataPoint) {
      const startOfToday = moment().startOf('day');
      const timePoint = moment(dataPoint.x);
      const numOfHours = timePoint.diff(startOfToday, 'hours');
      return numOfHours;
    },
    getHeaderElements(selectedOptionsDataPoints, aggregateConfig) {
      const values = selectedOptionsDataPoints
        .filter(dp => !isNil(dp[aggregateConfig.valueKey]))
        .map(dp => dp[aggregateConfig.valueKey]);
      const aggregateValue = aggregateConfig.combineElements(values);
      return { label: aggregateConfig.label, value: aggregateConfig.callFormatter(aggregateValue) };
    },
    renderMoreHeatmaps($state) {
      try {
        const currentIndex = this.lastVisibleIndex + 1;
        this.visibleHeatmaps.push(this.allHeatMapsData[currentIndex]);

        if (currentIndex >= this.allHeatMapsData.length - 1) {
          $state.complete();
        } else {
          $state.loaded();
        }
        this.lastVisibleIndex = currentIndex;
      } catch (error) {
        $state.error();
      }
    },
    resetVisibleHeatmaps() {
      this.visibleHeatmaps = [];
      this.lastVisibleIndex = -1;
    },
  },
  watch: {
    'metrics.lastFetchedAt': function watchMetricsLastFetchedAt() {
      if (this.selectedOptions.length) {
        this.prepareChartData();
      }

      this.resetVisibleHeatmaps();
    },
    selectedOptions(newSelections) {
      if (newSelections.length && this.metrics.lastFetchedAt) {
        this.prepareChartData();
      } else {
        this.allHeatMapsData = [];
      }

      this.resetVisibleHeatmaps();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/aiq-mixins.scss";
@import "@/styles/aiq-variables.scss";

.today-heatmaps-container {
  .all-heatmaps {
    @include scrollable(calc(100vh - 270px));
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
  }

  .no-selection {
    height: calc(100vh - 270px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>