<template lang="pug">
  management-page.scrollable(title="Summary"
                :creatable="false"
                :searchable="false"
                :showHeader="false")
    template(v-slot:body)
      aiq-row(:gutter="16" justify="end")
          .time-range-filter
            aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      .chart-grid
          aiq-row(:gutter="16")
              aiq-col(:span="24")
                  overview-chart(v-if="configs.active_customers"
                          :dateRange="dateRangeUtc"
                          :getTitleFromResponse="getTitleFromResponse"
                          :combineResponseConfigTitles="combineResponseConfigTitles"
                          :breakdown="selectedBreakdownOption.value"
                          :filters="selectedFilters"
                          :chartConfig="activeCustomersChartConfig"
                          :formatXTick="xAxisFormatForActiveCustomers"
                          yLabel="Count"
                          grouped=false
                          visualization='aiq-bar-chart')
      include ../selectableFilters/SelectableFilters.pug
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="24")
            overview-chart(v-if="configs.conversations_by_participants"
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="conversationbyAgentsChartConfig"
                          :filterResponseFields="conversationbyAgentsResponseFields"
                          :BucketOptions="filteredBreakdownOptions"
                          yLabel="Count"
                          visualization='aiq-bar-chart'
                          )
          aiq-col(:span="12")
            overview-chart(v-if="configs.conversations_by_participants"
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="conversationbyBotChartConfig"
                          :filterResponseFields="conversationbyBotsResponseFields"
                          :BucketOptions="filteredBreakdownOptions"
                          yLabel="Count"
                          visualization='aiq-bar-chart'
                          )
          aiq-col(:span="12")
            overview-chart(v-if="configs.conversations_by_participants"
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="conversationbyCustomersChartConfig"
                          :filterResponseFields="conversationbyCustomersResponseFields"
                          :BucketOptions="filteredBreakdownOptions"
                          yLabel="Count"
                          visualization='aiq-bar-chart'
                          )
          aiq-col(:span="24")
            overview-chart(v-if="configs.conversations_by_interaction"
                          :dateRange="dateRangeUtc"
                          :breakdown="selectedBreakdownOption.value"
                          :filters="selectedFilters"
                          :chartConfig="conversationbyInteractionChartConfig"
                          :formatXTick="xAxisFormatForInteraction"
                          :BucketOptions="filteredBreakdownOptions"
                          yLabel="Percent"
                          :yScaleMin=0
                          :yScaleMax=100
                          :formatYTick="percentFormatter"
                          grouped=true
                          visualization='aiq-bar-chart'
                          )
          aiq-col(:span="12")
            overview-chart(v-if="configs.conversations"
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="conversationsChartConfig"
                          :BucketOptions="filteredBreakdownOptions"
                          yLabel="Count"
                          grouped)

          aiq-col(:span="12")
            overview-chart(v-if="configs.hourly_new_conversations"
                          :dateRange="dateRangeUtc"
                          :breakdown="selectedBreakdownOption.value"
                          :filters="selectedFilters"
                          :chartConfig="hourlyConversationsChartConfig"
                          visualization="aiq-heat-map")

          aiq-col(:span="24")
            summary-metrics-summary-table(:dateRange="dateRangeUtc"
                                          :breakdown="selectedBreakdownOption.value"
                                          :selectedFilters="selectedFilters")
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import SummaryMetricsSummaryTable from './SummaryMetricsSummaryTable.vue';
import activeCustomersChartConfig from '../../../configs/activeCustomers';
import COLORS from '../../../configs/colors';

import conversationsChartConfig from '../../../configs/conversationActivity';
import conversationbyAgentsChartConfig from '../../../configs/conversationAgentParticipants';
import conversationbyBotChartConfig from '../../../configs/conversationbyBotChartConfig';
import conversationbyInteractionChartConfig from '../../../configs/conversationbyInteractionChartConfig';
import conversationbyCustomersChartConfig from '../../../configs/conversationCustomerParticipants';
import PlatformFiltersMixin from '../selectableFilters/SelectableFilters';
import debounce from 'lodash/debounce';

const xAxisFormatForActiveCustomers = (value) => moment(value).format('MMM DD');
const xAxisFormatForInteraction = (value) => moment(value).format('MMM DD');

const unresolvedChartConfig = {
  name: 'unresolved_conversations',
  title: 'Unresolved Type',
  metric: 'unresolved_conversations',
  lines: {
    unresolved_in_queue: {
      label: 'Queue Disconnected',
      position: 1,
      color: COLORS.red,
    },
    unresolved_by_agent: {
      label: 'Timeout with Agent',
      position: 2,
      color: COLORS.orange,
    },
    unresolved_by_ai: {
      label: 'Timeout with AI',
      position: 3,
      color: COLORS.yellow,
    },
    unresolved_email_shown: {
      label: 'Email Discard',
      position: 4,
      color: COLORS.grey,
    },
    unresolved_email_submit: {
      label: 'Email Sent',
      position: 5,
      color: COLORS.blue,
    },
  },
  helpText: `<div>
      Breakdown of unresolved conversations.<br><br>
      <b>Queue Disconnected</b>: Customer disconnects while waiting in queue<br><br>
      <b>Agent Disconnnected</b>: Customer disconnects while talking with human agent<br><br>
      <b>AI Disconnected</b>: Customer disconnects while talking with AI<br><br>
      <b>Email Discard</b>: Customer is shown an email form but doesn’t submit<br><br>
      <b>Email Sent</b>: Customer is shown an email form and submits</div>`,
};

const responseTimeChartConfig = {
  name: 'first_response_times',
  title: 'First Response Time',
  metric: 'conversations_times',
  lines: {
    max_first_agent_reply_time: {
      label: 'Max',
      position: 1,
      type: 'dashed',
      color: COLORS.red,
    },
    median_first_agent_reply_time: {
      label: 'Median',
      position: 2,
      type: 'line',
      color: COLORS.orange,
    },
    min_first_agent_reply_time: {
      label: 'Min',
      position: 3,
      type: 'dashed',
      color: COLORS.blue,
    },
  },
  helpText:
    'The median of a team’s/agent’s first response time from the first inbound message to the first reply. Displays the median first chat response time started as a function of time.',
};

const hourlyConversationsChartConfig = {
  name: 'hourly_new_conversations',
  title: 'Hourly Conversation Volume',
  metric: 'metrics_by_hour',
  lines: {
    metrics_by_hour: {
      valueKey: 'median_conversation_volume',
      xKey: 'hour',
      yKey: 'day',
    },
  },
  helpText: 'Displays the weekly volume maximum. Colors show conversation volume.<br>Hover to see the weekly conversation average, by hour.',
  exportType: 'tabular_chart_data',
};

const queueTimeChartConfig = {
  name: 'queue',
  title: 'Queue',
  metric: 'queue',
  lines: {
    max_queue_time: {
      label: 'Max Queue Time',
      position: 1,
      type: 'dashed',
      color: COLORS.red,
    },
    median_queue_time: {
      label: 'Median Queue Time',
      position: 2,
      type: 'line',
      color: COLORS.orange,
    },
    queue_size: {
      label: 'Median Queue Size',
      position: 3,
      axis: 'y2',
      type: 'bar',
      color: COLORS.blue,
    },
  },
  helpText: `<div>
      Displays the median queue size with the median and max queue times for all queued conversations.<br><br>
      <b>Queue</b>: When a customer is waiting to be transferred from the Virtual Assistant to an agent.<br><br>
      <b>Queue Size</b>: How many customers are waiting in the queue.<br><br>
      <b>Queue Time</b>: Time customers wait in the queue.
      </div>`,
};

const resolutionTimeChartConfig = {
  name: 'conversation_resolution_times',
  title: 'Median Resolution Time',
  metric: 'conversations_times',
  lines: {
    median_ai_handle_time: {
      label: 'AI Handle Time',
      position: 1,
      type: 'area-spline',
      color: COLORS.orange,
    },
    median_agent_handle_time: {
      label: 'Agent Handle Time',
      position: 2,
      type: 'area-spline',
      color: COLORS.red,
    },
    median_resolution_time: {
      label: 'Resolution Time',
      position: 3,
      type: 'area-spline',
      color: COLORS.blue,
    },
  },
  helpText: `<div>
    <b>Agent Handle Time</b>: Median time between agent taking on a conversation until that conversation is completed.<br><br>
    <b>AI Handle Time</b>: Median time of AI handling the conversation.<br><br>
    <b>Resolution Time</b>: Total time from start of a conversation until it is completed.
    </div>`,
};

const conversationRatingsChartConfig = {
  name: 'survey_resolution',
  title: 'Overall Conversation Resolutions',
  metric: 'survey_resolution',
  lines: {
    no: {
      label: 'No',
      position: 1,
      color: COLORS.red,
    },
    no_reply: {
      label: 'No Reply',
      position: 2,
      color: COLORS.orange,
    },
    yes: {
      label: 'Yes',
      position: 3,
      color: COLORS.yellow,
    },
  },
  helpText: 'Displays the extent to which customer believes inquiry was solved.',
};

export default {
  pageResource: '/metrics/platform',
  components: {
    SummaryMetricsSummaryTable,
    ManagementPage,
    OverviewChart,
  },
  mounted() {
    try {
      this.loadAllFilterOptions();
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  },
  watch: {
    dateRange: debounce(function onDateChange(newValue, oldValue = []) {
      const [start, end] = oldValue;
      if (!(start && end)) {
        return;
      }
      this.loadAllFilterOptions();
    }, 1),
  },
  mixins: [PlatformFiltersMixin],
  data() {
    const chartConfigs = [
      activeCustomersChartConfig,
    ];

    for (const config of chartConfigs) {
      config.title = this.$t(`metrics_tab.plaform_tab.summary_tab.${config.name}.title`, config.title);
    }

    return {
      conversationsChartConfig,
      conversationbyAgentsChartConfig,
      conversationbyBotChartConfig,
      conversationbyCustomersChartConfig,
      activeCustomersChartConfig,
      conversationbyInteractionChartConfig,
      xAxisFormatForActiveCustomers,
      xAxisFormatForInteraction,
      unresolvedChartConfig,
      responseTimeChartConfig,
      resolutionTimeChartConfig,
      hourlyConversationsChartConfig,
      queueTimeChartConfig,
      conversationRatingsChartConfig,
      conversationbyAgentsResponseFields: [
        'multiple_agents',
        'single_agents',
        'zero_agents',
        'single_bots',
        'zero_bots',
        'total_conversations',
      ],
      conversationbyBotsResponseFields: [
        'single_bots',
        'zero_bots',
        'total_conversations',
      ],
      conversationbyCustomersResponseFields: ['single_customers', 'zero_customers', 'total_conversations'],
      percentFormatter: v => `${v}%`,
      durationFormatter: this.$aiq.formatters.timeDelta,
    };
  },
  computed: {
    ...mapGetters({
      configs: 'configs/graphsConfigs',
    }),
  },
  methods: {
    getTitleFromResponse(response) {
      const obj = get(get(response, 'dataset', []).filter(dp => dp.metric === 'total_active'),
        '0.data_points.0', {});
      return get(obj, 'ys.0.value', '');
    },
    combineResponseConfigTitles(configTitle, responseTitle) {
      if (isEmpty(configTitle)) {
        return '';
      }
      if (isUndefined(responseTitle)) {
        return configTitle;
      }
      return `${responseTitle} ${configTitle}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-variables.scss";
@import "../selectableFilters/SelectableFilters.scss";

.scrollable {
  overflow-y: auto;
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.time-range-filter {
  position: relative;
  margin-bottom: 24px;
}

:deep(.c3-circles-total_convos) {
opacity: 0 !important;
}

.chart-configuration {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  .breakdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .members-filter {
    display: flex;
    align-items: center;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }
}
</style>
