<template lang="pug">
.close-conversation-configuration(v-if="eventConfig")
  aiq-row.sub-container.center-align
      aiq-col(:span="6")
        label.table-header {{ $t(`settings_tab.channels_tab.webchat_configuration.close_conv_event.event`) }}
      aiq-col(:span="18")
        label.table-header {{ $t(`settings_tab.channels_tab.webchat_configuration.close_conv_event.segments`) }}
  .sub-container(v-for="actorField in actorList" key="actorField")
    label.sub-container-header {{ $t(`settings_tab.channels_tab.webchat_configuration.close_conv_event.${actorField}`) }}
      aiq-tooltip(placement="top" :content="$t(`settings_tab.channels_tab.webchat_configuration.close_conv_event.${actorField}_tooltip`)")
        fa-icon.tooltip-icon(:icon="['fas', 'circle-question']")
    .sub-container-body.margin-top-7
      aiq-row.padding-bottom-5
        aiq-col.center-label(:span="6")
          label.padding-left-12 {{ $t('settings_tab.channels_tab.webchat_configuration.close_conv_event.menu.send_feedback') }}
        aiq-col(:span="18")
          aiq-select(v-model="eventConfig.closed_event[actorField].send_feedback.segments"
                    size="small"
                    multiple
                    :popper-class="returnPopperClass(actorField)"
                    @change="onValueChange(actorField, 'send_feedback', $event)"
                    name="segments"
                    :reserve-keyword="false")
            aiq-option(v-for="segment in segments"
                      :key="segment"
                      :label="segment"
                      :value="segment")

      aiq-row.padding-bottom-5
        aiq-col.center-label(:span="6")
          label.padding-left-12 {{ $t('settings_tab.channels_tab.webchat_configuration.close_conv_event.menu.remove_all_agents') }}
        aiq-col(:span="18")
          aiq-select(v-model="eventConfig.closed_event[actorField].remove_all_agents.segments"
                    size="small"
                    multiple
                    :popper-class="returnPopperClass(actorField)"
                    @change="onValueChange(actorField, 'remove_all_agents', $event)"
                    name="segments"
                    :reserve-keyword="false")
            aiq-option(v-for="segment in segments"
                      :key="segment"
                      :label="segment"
                      :value="segment")
</template>

<script>
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import * as log from 'loglevel';


export default {
  name: 'ConversationEventConfiguration',
  props: {
    eventConfig: {
      type: Object,
      default: null,
    },
    segments: {
      type: Array,
      default: [],
    },
  },
  computed: {
    actorList() {
      return ['by_agent', 'by_customer', 'by_system'];
    },
  },
  methods: {
    onValueChange(actorField, configField, value) {
      const newConfig = cloneDeep(this.eventConfig);
      log.debug('value updated', actorField, configField, value);
      set(newConfig.closed_event, `${actorField}.${configField}.segments`, value);
      this.$emit('update', newConfig);
    },
    returnPopperClass(actorField){
      if (['by_customer', 'by_system'].includes(actorField)){
        return 'render-options-from-bottom';
      }
      return '';
    }
  },
};
</script>

<style>
.el-select-dropdown.render-options-from-bottom{
  top: unset !important;
  bottom: calc(100% + 40px);
}
</style>

<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";
@import "./../../../../../styles/aiq-mixins.scss";

.config-item {
  border: 1px solid #E0E6ED;
  padding: 10px;
  margin-bottom: 10px;
}

.sub-container {
  padding: 10px;
}

.sub-container-header {
  letter-spacing: 0;
  font-family: "museo sans", "Roboto", sans-serif;
  font-size: 14px;
  padding-bottom: 10px;
}

.padding-bottom-5 {
  padding-bottom: 10px;
}

.center-label {
  padding-top: 10px;
}

.center-btn {
  text-align: center;
}
.add-btn {
  margin-left: 5px;
}

.center-align{
  display: flex;
  align-items: center;
}

.padding-left-12{
  padding-left: 24px;
}
.tooltip-icon{
  color:#6B6D70;
  margin-left: 5px;
}
.table-header{
  font-weight: 600;
  color: #28292C;
}
.margin-top-7{
  margin-top: 14px ;
}
</style>
