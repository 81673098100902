import { Component, Vue, toNative } from 'vue-facing-decorator';
import { mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import { alphabetSort } from '@/libs';
import { SIDE_PANEL_TAB_NAMES } from '@/constants';
import { PROFILE_EDITOR_TYPE } from '@/constants/settings';
import { SIDE_PANEL_PREFIX, CUSTOMER_INFO } from '@/constants/analytics';
import {
  ConversationMetaList,
  KnowledgeBase,
  History,
  NotesList,
  CustomerInfo,
  MyToolBox,
} from './children';
import isEmpty from 'lodash/isEmpty';

const SIDE_PANEL_MAX_DROPBOX_ITEMS = 1000;

@Component({
  name: 'sidePanel',
  components: {
    ConversationMetaList,
    KnowledgeBase,
    History,
    NotesList,
    CustomerInfo,
    MyToolBox,
  },
  props: {
    videoPadding: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: [],
    },
  },
  emits: ['selectResponse'],
  watch: {
    conversation() {
      const firstName = get(this.customer, 'profile.first_name', '');
      const lastName = get(this.customer, 'profile.last_name', '');
      this.customerName = `${firstName} ${lastName}`.trim();
    },
    selectedCollapseTab(value, old) {
      if (value !== old) { this.selectedCollapse = value }
    },
  },
  computed: {
    ...mapGetters({
      teamsListState: 'teams/sortedTeams',
      isRealtimeContext: 'conversations/isRealtimeContext',
    }),
    ...mapState({
      conversation: state => state.conversations.selected,
      selectedCustomer: state => state.conversations.selectedCustomer,
      lastFeedbackInfo: state => state.conversations.selectedLastFeedbackInfo,
      notes: state => state.conversations.notes,
      schedules: state => state.conversations.schedules,
      tagsListState: state => state.tags.tags || [],
      categoriesListState: state => state.categories.categories || [],
      selectedCollapseTab: state => state.conversations.openedSidebarTab,
      tenantVariables: state => state.configs.tenantVariables,
      sidePanelConfig: state => state.configs.config.side_panel,
      FEATURE_FLAGS: state => state.featureFlags.FEATURE_FLAGS,
    }),
    customer() {
      return this.selectedCustomer || {};
    },
    showKnowledgeBase() {
      return Boolean(this.FEATURE_FLAGS.KNOWLEDGE_BASE);
    },
    noteHeading() {
      return this.FEATURE_FLAGS.SHOW_SCHEDULING ? 'Notes and Reminders' : 'Notes';
    },
    categories() {
      return Array.of.apply(null, this.conversation.categories).sort(alphabetSort);
    },
    tags() {
      return Array.of.apply(null, this.conversation.tags).sort(alphabetSort);
    },
    teams() {
      return Array.of.apply(null, this.conversation.teams).sort(alphabetSort);
    },
    categoriesList() {
      return Array.of.apply(
        null, this.categoriesListState,
      ).filter(
        e => !this.categories.find(j => j.id === e.id),
      ).sort(alphabetSort);
    },
    tagsList() {
      return this.tagsListState.filter(e => !this.tags.find(j => j.id === e.id)).sort(alphabetSort);
    },
    teamsList() {
      return this.teamsListState.filter(e => !this.teams.find(j => j.id === e.id));
    },
  },
  methods: {
    getProfileValue(type, mapping) {
      if (type === PROFILE_EDITOR_TYPE.CONCAT_STRING) {
        const strs = [];
        for (const subMapping of get(mapping, 'fields', [])) {
          strs.push(get(this.customer.profile, subMapping.field, ''));
        }
        return strs.join(' ').trim();
      }
      if (type === PROFILE_EDITOR_TYPE.STRING_LIST) {
        return (get(this.customer.profile, mapping.field, []) || []).join(', ').trim();
      }
      return get(this.customer.profile, mapping.field, '');
    },
    onSelectResponse(content) {
      for (const mapping of this.sidePanelConfig.profile_mapping) {
        const type = get(mapping, 'type', PROFILE_EDITOR_TYPE.STRING);
        let value = this.getProfileValue(type, mapping);
        content = value ? content.replace(new RegExp(`#{${mapping.label}}`, 'g'), value) : content;

        const fields = get(mapping, 'fields', [mapping.field]);
        for (const field of fields) {
          const key = get(field, 'field', field);
          value = type === PROFILE_EDITOR_TYPE.CONCAT_STRING ? get(this.customer.profile, key, '') : value;
          content = value ? content.replace(new RegExp(`#{${key}}`, 'g'), value) : content;
        }
      }
      content = content?.replace(/\n/g, '<br/>');
      this.$emit('selectResponse', content);
    },
  },
})
class SidePanel extends Vue {
  sidePanelTabNames = SIDE_PANEL_TAB_NAMES;
  selectedCollapse = '';
  customerName = '';

  mounted() {
    this.$store.dispatch('tags/getTagsList', [{ limit: SIDE_PANEL_MAX_DROPBOX_ITEMS }]);
    this.$store.dispatch('categories/getCategoriesList', [{ limit: SIDE_PANEL_MAX_DROPBOX_ITEMS }]);
    this.$store.dispatch('teams/getTeamsList', [{ limit: SIDE_PANEL_MAX_DROPBOX_ITEMS }]);
    const { last_name = '', first_name = '' } = this.customer.profile;
    this.customerName = `${first_name} ${last_name}`.trim();
    this.selectedCollapse = this.selectedCollapseTab;
  }

  addNote(note) {
    this.$store.dispatch('conversations/addNote', [this.conversation.id, note]);
  }

  removeNote(id) {
    this.$store.dispatch('conversations/removeNote', [this.conversation.id, id]);
  }

  addTags(tags = []) {
    this.updateTags(tags.map(e => e.id), 'added');
  }

  createTag(name) {
    this.$store.dispatch('tags/createTag', [{ name }, 'data'])
      .then(data => {
        this.updateTags([...this.tags.map(e => e.id), data.data.id])
          .then(() => {
            this.$aiq.notify.info('New tag was created successfully.');
          });
      });
  }

  onTabClickChange(tabName) {
    if (!isEmpty(tabName)) {
      this.$store.dispatch(
        'notifications/emitEventToSocket',
        {
          name: `${SIDE_PANEL_PREFIX}.${tabName.toLowerCase()}.clicked`,
          payload: { agent: this.agent },
        },
      );
    }
    this.$store.dispatch('conversations/setSidePanelTab', tabName);
  }

  removeTag(id) {
    this.updateTags(this.tags.filter(e => e.id !== id).map(e => e.id), 'removed');
  }

  updateTags(tags, updateType = 'updated') {
    return this.$store.dispatch('conversations/updateTags',
      [this.conversation.id,
        tags,
        updateType,
      ]);
  }

  addCategories(categories = []) {
    this.updateCategories(categories.map(e => e.id), 'added');
  }

  createCategory(category = '') {
    this.$store.dispatch('categories/createCategory', [{ name: category }, 'data'])
      .then(data => {
        this.updateCategories([...this.categories.map(e => e.id), data.data.id]).then(() => {
          this.$aiq.notify.info('Category was created successfully.');
        });
      });
  }

  removeCategory(id) {
    this.updateCategories(
      this.categories.filter(e => e.id !== id).map(e => e.id),
      'removed',
    );
  }

  updateCategories(categories, updateType = 'updated') {
    return this.$store.dispatch('conversations/updateCategories',
      [this.conversation.id,
        categories,
        updateType,
      ]);
  }

  addTeams(teams = []) {
    this.updateTeams(teams.map(e => e.id), 'added');
  }

  createTeam(team = '') {
    this.$store.dispatch('teams/createTeam', [{ name: team }, 'data'])
      .then(data => {
        this.updateTeams([...this.teams.map(e => e.id), data.data.id])
          .then(() => {
            this.$aiq.notify.info('Team was created successfully.');
          });
      });
  }

  removeTeam(id) {
    this.updateTeams(
      this.teams.filter(e => e.id !== id).map(e => e.id),
      'removed',
    );
  }

  updateTeams(teams, updateType = 'updated') {
    return this.$store.dispatch('conversations/updateTeams',
      [this.conversation.id, teams, updateType]);
  }
  saveCustomer() {
    const customerName = this.customerName.split(' ');
    const { last_name = '', first_name = '' } = this.customer.profile;
    const temporal = `${first_name} ${last_name}`.trim();
    if (this.customerName.trim() === temporal) return;
    const user = {
      last_name: '',
      fist_name: '',
    };
    const shortNameLength = 2;
    if (customerName.length >= shortNameLength) {
      user.last_name = customerName.pop();
    }
    user.first_name = customerName.join(' ');
    let newCustomer = this.customer.profile;
    newCustomer = Object.assign({ ...newCustomer }, user);
    this.updateProfile(newCustomer);
  }

  updateProfile(profile, id = this.customer.id) {
    this.$store.dispatch('conversations/updateCustomer', [
      id,
      { ...this.customer, profile },
    ]);
    this.$store.dispatch(
      'notifications/emitEventToSocket',
      {
        name: CUSTOMER_INFO.UPDATED,
        payload: { agent: this.agent, customer: { id, profile } },
      },
    );
  }
}
export default toNative(SidePanel);
