<template lang="pug">
.profile-mapping
  // - Display
  .added-fields
    .field-group(v-for="mapping in mappings")
      .name-and-value
        h4 Display Label: 
      .name-and-value
        aiq-input(:model-value="mapping.label"
                 @blur="onLabelChangeDone(mapping, $event)"
                 @keyup.enter="proxyBlur"
                 @update:modelValue="updateLabel(mapping, $event)"
                 )

      .name-and-value
        h4 Field:
      .name-and-value(v-if="isConcatString(mapping)")
        aiq-input(:model-value="mapping.fields.map(f => f.field).join(',')" :disabled="true")
      .name-and-value(v-else)
        aiq-input(:model-value="mapping.field" :disabled="true")

      .name-and-value
        aiq-checkbox(:model-value="mapping.editable"
                     @change="changeFieldInMapping(mapping, { editable: $event })"
                     :disabled="isStringList(mapping)") Edit in Dashboard

      .name-and-value
        aiq-checkbox(:model-value="mapping.syncable"
                     @change="onSyncChange(mapping, $event)"
                     :disabled="isStringList(mapping)") Sync with Api
        aiq-button.left-space(v-if="mapping.syncable"
                              size="small"
                              @click="onEditApi(mapping, $event)") Configuration

      .name-and-value
        aiq-button(type="danger" :disabled="!mapping.customizable" @click="removeItem(mapping)") Remove

  // - Add new 
  .add-new-field
    .field-group
      .name-and-value
        h4 Display Label: 
      .name-and-value
        aiq-input(v-model="newFieldLabel")

      .name-and-value
        h4 Field:
      .name-and-value
        aiq-select.field-item(v-model="newFieldValue" placeholder="Field")
          aiq-option(v-for="item in unusedFields"
                    :key="item"
                    :label="item"
                    :value="item")

      .name-and-value
        aiq-button(@click="addItem" :disabled="!canAdd") Add
  sync-api-dialog(title="Api Editor"
                  :visible="showApiEditor"
                  :mapping="tempMapping"
                  :apiInfo="tempApiInfo"
                  @close="onSaveSyncApiClose"
                  @error="$aiq.notify.error($event)"
                  @save="onSaveSyncApiChange")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import pick from 'lodash/pick';
import difference from 'lodash/difference';
import { PROFILE_EDITOR_TYPE } from '@/constants/settings';
import SyncApiDialog from './SyncApiDialog.vue';
import * as log from 'loglevel';

const TAG = '[ProfileMapping]';
const apiInfoDefault = {
  domain_name: '',
  endpoint_name: '',
  entities: [],
  request_entities: {},
  response_entities: { value: '' },
};

export default {
  name: 'ProfileMapping',
  components: {
    SyncApiDialog,
  },
  computed: {
    canAdd() {
      return this.newFieldValue && this.newFieldLabel;
    },

    isProfileChanged() {
      return !isEqual(this.localItems, this.items);
    },

    unusedFields() {
      const candidates = this.items.map(i => i.field);
      const used = this.mappings.reduce((acc, val) => {
        if (val.type === PROFILE_EDITOR_TYPE.CONCAT_STRING) {
          val.fields.forEach(i => acc.push(i.field));
        } else {
          acc.push(val.field);
        }
        return acc;
      }, []);
      return difference(candidates, used);
    },
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
    mappings: {
      type: Array,
      default: [],
    },
  },
  emits: ['change', 'update'],
  data() {
    return {
      ...this.getDefault(),
      showApiEditor: false,
      tempMapping: null,
      tempApiInfo: {
        domain_name: '',
        endpoint_name: '',
        entities: [],
        requestPairs: [],
        responsePairs: ['value', ''],
      },
      customerDisplayConfiguration: [],
    };
  },
  watch: {
    newFieldValue(newValue) {
      this.newFieldType = get(this.items.find(item => item.field === newValue),
        'type', PROFILE_EDITOR_TYPE.STRING);

      // Do not support editing on array for now
      if (this.newFieldType === PROFILE_EDITOR_TYPE.STRING_LIST) {
        this.newFieldEditable = false;
      }
    },

  },
  methods: {
    isStringList(mapping) {
      return mapping.type === PROFILE_EDITOR_TYPE.STRING_LIST;
    },

    isConcatString(mapping) {
      return mapping.type === PROFILE_EDITOR_TYPE.CONCAT_STRING;
    },

    proxyBlur(evt) {
      // send blur event
      evt.target.blur();
    },
    findEditedMappingIndex(mapping){
      if (this.isConcatString(mapping)) {
        const mappingFields = mapping?.fields.map(field => field?.field);
        const itemFields = item => item?.fields?.map(subField => subField?.field) || [];
        
        // eslint-disable-next-line no-undef
        return this.mappings.findIndex(item => _.isEqual(mappingFields, itemFields(item)));
      }
      return this.mappings.findIndex(item => item?.field === mapping?.field);
    },
    updateLabel(mapping, newValue) {
      const editedIndex = this.findEditedMappingIndex(mapping);
      if(editedIndex>=0){
        let mappings = [...this.mappings];
        mappings[editedIndex].label = newValue;
        this.$emit('update', mappings);
      }
    },
    onLabelChangeDone(mapping, evt) {

      const value = get(evt, 'target.value', '').trim();
      const defaultLabelIndex = this.findEditedMappingIndex(mapping);
      const defaultLabel = this.customerDisplayConfiguration?.[defaultLabelIndex]?.label;
      
      if (!mapping || defaultLabel === value || !value) {
        return;
      }

      const changed = cloneDeep(this.mappings);
      changed.forEach(m => {
        if (this.isMappingEqual(m, mapping)) {
          m.label = value;
        }
      });

      this.$emit('change', changed);
      this.customerDisplayConfiguration = [...changed];
    },

    getDefault() {
      return {
        newFieldLabel: '',
        newFieldType: 'string',
        newFieldValue: '',
        newFieldEditable: false,
        newFieldSyncable: false,
      };
    },
    reset() {
      for (const [key, value] of Object.entries(this.getDefault())) {
        this[key] = value;
      }
    },
    removeItem(removedMapping) {
      const changed = this.mappings.filter(m => !this.isMappingEqual(m, removedMapping));
      this.$emit('change', changed);
    },
    isMappingEqual(m1, m2) {
      return m1.field === m2.field && m1.label === m2.label;
    },
    addItem() {
      const changed = cloneDeep(this.mappings);

      changed.push({
        field: this.newFieldValue,
        label: this.newFieldLabel,
        type: this.newFieldType,
        editable: this.newFieldEditable,
        customizable: true,
      });

      this.$emit('change', changed);
      this.reset();
    },
    changeFieldInMapping(mapping, updated) {
      const changed = cloneDeep(this.mappings);
      changed.forEach((m, index) => {
        if (this.isMappingEqual(m, mapping)) {
          changed[index] = { ...m, ...updated };
        }
      });

      log.debug(TAG, 'changeFieldInMapping', mapping, updated, changed);
      this.$emit('change', changed);
    },
    onSyncChange(mapping, value) {
      log.debug(TAG, 'onSyncChange', value);
      this.changeFieldInMapping(mapping, { syncable: value });
    },
    onEditApi(mapping) {
      this.tempMapping = mapping;
      this.tempApiInfo = this.convertToApiReqResPairs(get(mapping, 'apiInfo', apiInfoDefault));
      this.showApiEditor = true;
    },
    convertToApiReqResPairs(apiInfo) {
      return {
        ...pick(apiInfo, ['domain_name', 'endpoint_name', 'entities']),
        requestPairs: Object.entries(get(apiInfo, 'request_entities', {})),
        responsePairs: Object.entries(get(apiInfo, 'response_entities', {})),
      };
    },
    convertToApiReqResEntities(apiInfo) {
      return {
        ...pick(apiInfo, ['domain_name', 'endpoint_name', 'entities']),
        request_entities: get(apiInfo, 'requestPairs', []).reduce((obj, item) => {
          obj[item[0]] = item[1];
          return obj;
        }, {}),
        response_entities: get(apiInfo, 'responsePairs', []).reduce((obj, item) => {
          obj[item[0]] = item[1];
          return obj;
        }, {}),
      };
    },
    onSaveSyncApiChange(mapping, updatedApiInfo) {
      log.debug(TAG, 'onSaveSyncApiChange', mapping, updatedApiInfo);
      this.showApiEditor = false;
      this.changeFieldInMapping(mapping, {
        syncable: true,
        apiInfo: this.convertToApiReqResEntities(updatedApiInfo),
      });
    },
    onSaveSyncApiClose() {
      this.tempMapping = null;
      this.showApiEditor = false;
    },
  },
  updated(){
    if(!this.customerDisplayConfiguration?.length){
      this.customerDisplayConfiguration = [...this.mappings];
    }
  }
};
</script>

<style lang="scss" scoped>
.field-group {
  display: flex;
}

.name-and-value {
  padding: 5px;
  h4, label {
    padding-top: 5px;
  }
  .el-select.field-item {
    width: 150px;
  }
}

.left-space {
  margin-left: 5px;
}

</style>
