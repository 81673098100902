<template lang='pug' src='./Base.pug'>
</template>

<script>
import c3 from 'c3';
import moment from 'moment';
import * as d3 from 'd3';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { timeDelta } from '../../libs/formatters';
import COLORS from '../../pages/Metrics/configs/colors';
import { cloneDeep } from 'lodash';

/* TODO: VUE3
require.config({
  baseUrl: '/js',
  paths: {
    d3: 'http://d3js.org/d3.v5.min',
  },
});
*/

const setLinesSelectionState = (lines, state) => {
  if (isEmpty(lines)) {
    return [];
  }
  return Object.keys(lines).reduce((selectionStates, key) => { // eslint-disable-line
    selectionStates[key] = state;
    return selectionStates;
  }, {});
};

const defaultFormatter = v => v;

const tooltipFormatters = {
  count: defaultFormatter,
  default: defaultFormatter,
  percent: v => `${v}%`,
  score: defaultFormatter,
  timedelta: timeDelta,
};

const sanitize = function (str) { // eslint-disable-line
  return typeof str === 'string' ? str.replace(/</g, '&lt;').replace(/>/g, '&gt;') : str;
};

export default {
  name: 'aiqChart',
  props: {
    report: {
      type: Object,
      required: true,
    },
    linesColor: {
      type: String,
      default: () => COLORS.blue,
    },
    graphParams: {
      type: Object,
      default: () => {},
    },
    y2Color: {
      type: String,
      default: () => COLORS.red,
    },
    lines: {
      type: Object,
      default: () => {},
    },
    lineOrder: {
      type: Function,
      default: () => null,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    keepChartOnly: {
      type: Boolean,
      default: false,
    },
    rightPadding: {
      type: Number,
      default: 44000000,
    },
    leftPadding: {
      type: Number,
      default: 44000000,
    },
    formatXTick: {
      type: Function,
      default: value => moment(value).format('MM-DD-Y'),
    },
    rotateXTick: {
      type: Number,
      default: 0,
    },
    formatYTick: {
      type: Function,
      default: d3.format(','),
    },
    formatY2Tick: {
      type: Function,
      default: d3.format(','),
    },
    tooltipOrder: {
      type: Function,
      default: null,
    },
    yLabel: {
      type: String,
    },
    yLabelPosition: {
      type: String,
      default: 'outer-top',
    },
    y2ScaleMax: {
      type: Number,
      default: () => null,
    },
    y2ScaleMin: {
      type: Number,
      default: () => null,
    },
    yScaleMax: {
      type: Number,
      default: () => null,
    },
    yScaleMin: {
      type: Number,
      default: () => null,
    },
    interpolation: {
      type: String,
      default: () => 'monotone',
    },
    showYGridLines: {
      type: Boolean,
      default: () => true,
    },
    xTickValues: {
      type: Array,
      default: () => null,
    },
    yTickValues: {
      type: Array,
      default: () => null,
    },
  },
  emits: ['onChartStatus', 'onLineClick'],
  computed: {
    showLegendSelectAll() {
      return !['heatMap', 'funnelChart', 'CategoryBar', 'donutChart'].includes(this.getChartType());
    },
  },
  watch: {
    report: {
      handler() {
        this.renderChart();
      },
      deep: true,
    },
    lines: {
      handler(value) {
        this.selectedLines = setLinesSelectionState(value, true);
        this.renderChart();
      },
    },
    selectedLines: {
      handler(value) {
        const linesCount = Object.keys(value).length;
        const selectedLinesCount = Object.values(value).reduce(
          (result, isSelected) => (isSelected ? result + 1 : result),
          0,
        );

        this.allLinesSelected = selectedLinesCount === linesCount;
        this.someLinesSelected =
          selectedLinesCount > 0 && selectedLinesCount < linesCount;
        this.noLinesSelected = selectedLinesCount === 0;
        this.refreshLines();
      },
      deep: true,
    },
    chart: {
      handler() {
        if (get(this.chart, 'axis')) {
          this.refreshLines();
        }
      },
    },
  },
  data() {
    return {
      chart: null,
      selectedLines: setLinesSelectionState(this.lines, true),
      allLinesSelected: true,
      someLinesSelected: false,
      noLinesSelected: false,
      showY2Axis: false,
      showLegend: true,
    };
  },
  mounted() {
    this.renderChart();
  },
  beforeUnmount() {
    this.DestroyChart();
    this.chart = null;
  },
  methods: {
    getShowLegend() {
      return this.showLegend;
    },
    setShowLegend(value) {
      this.showLegend = Boolean(value);
    },
    dataFilter(data) {
      if (isEmpty(this.lines)) {
        return false;
      }
      return Boolean(this.lines[data.metric]);
    },
    dataSort(a, b) {
      if (isEmpty(this.lines)) {
        return false;
      }
      const aPosition = this.lines[a.metric].position;
      const bPosition = this.lines[b.metric].position;
      return aPosition > bPosition;
    },
    getChartType() {
      return 'line';
    },
    getLineType({ type }) {
      switch (type) {
        case 'dashed':
          return this.getChartType();
        case undefined:
          return this.getIsGrouped() ? 'bar' : this.getChartType();
        default:
          return type;
      }
    },
    getLineColor(line) {
      if (has(this.graphParams, 'colColors')) {
        return this.colorChooser(line.position);
      }
      return line.color;
    },
    getIsGrouped() {
      return this.grouped;
    },

    getPrimaryYAxis() {
      return 'y';
    },
    buildGroups(columns) {
      return this.getIsGrouped() ? [columns.map(col => col[0])] : this.groups;
    },
    dataConfig() {
      const dataset = Array.of
        .apply(null, cloneDeep(this.report.dataset))
        .filter(this.dataFilter)
        .sort(this.dataSort);
      const {
        axes,
        colors,
        columns,
        meta,
        names,
        regions,
        types,
      } = this.setupConfig(dataset,
        get(this.graphParams, 'metricCategoryForY', 'count'));
      const BaseComponent = this;
      // saving to use inside another object with different this
      return {
        axes,
        colors,
        columns,
        names,
        regions,
        types,
        classes: meta,
        groups: this.buildGroups(columns),
        onclick(dataPoint) {
          BaseComponent.$emit('onLineClick', dataPoint);
        },
        order: this.lineOrder,
        x: 'labels',
      };
    },
    xPaddingConfig() {
      return {
        right: this.rightPadding,
        left: this.leftPadding,
      };
    },
    xAxisConfig() {
      return {
        type: 'timeseries',
        tick: {
          format: this.formatXTick,
          count: 0,
          values: this.xTickValues,
          rotate: this.rotateXTick,
        },
        padding: this.xPaddingConfig(),
      };
    },
    toolTipConfig() {
      return {
        order: this.tooltipOrder,
        contents: this.renderTooltipContent,
      };
    },
    chatContainerSelector() {
      return this.$el.querySelector('.chart-container');
    },
    c3GenerateParams() {
      return {
        bindto: this.chatContainerSelector(),
        data: this.dataConfig(),
        spline: {
          interpolation: {
            type: this.interpolation,
          },
        },
        bar: {
          width: {
            ratio: 0.5, // this makes bar width 50% of length between ticks
          },
        },
        axis: {
          x: this.xAxisConfig(),
          y: {
            tick: {
              format: this.formatYTick,
              values: this.yTickValues,
              padding: {
                top: 0,
                bottom: 0,
              },
            },
            label: {
              text: this.yLabel,
              position: this.yLabelPosition,
            },
            max: this.yScaleMax,
            min: this.yScaleMin,
            padding: 0,
          },
          y2: {
            show: this.showY2Axis,
            max: this.y2ScaleMax,
            min: this.y2ScaleMin,
            tick: {
              format: this.formatY2Tick,
            },
            padding: 0,
          },
        },
        grid: {
          y: {
            show: this.showYGridLines,
          },
        },
        legend: {
          show: false,
        },
        tooltip: this.toolTipConfig(),
        onrendered: this.onChartRendered,
        onresized: this.onChartResized,
      };
    },
    DestroyChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    },
    renderChart() {
      this.DestroyChart();
      this.$emit('onChartStatus', 'onstart');
      const ctx = this;
      // eslint-disable-next-line
      require(['d3', 'c3'], () => {
        ctx.chart = c3.generate(ctx.c3GenerateParams());
      });
    },
    setLineBasedChartParams(line, chartData, id) {
      if (isEmpty(chartData) || isEmpty(line)) {
        return;
      }
      chartData.axes[id] = line.axis || this.getPrimaryYAxis();
      chartData.colors[id] = this.getLineColor(line);
      chartData.names[id] = line.label;
      chartData.types[id] = this.getLineType(line);

      if (line.type === 'dashed') {
        chartData.regions[id] = [{ style: 'dashed' }];
      }
    },
    getYCategoryValue(yCats, metricCategoryForY){
      return yCats.filter(y => y.metric_category === metricCategoryForY)[0].value;
    },
    getAxisBasedCategory(line, metricCategoryForY) {
      if (line.axis === 'y2' && !isEmpty(line.y2Category)){
        return line.y2Category;
      }
      return metricCategoryForY;
    },
    setupConfig(dataset, metricCategoryForY= 'count') {
      return dataset.reduce(
        (chartData, column, index) => {
          const id = column.metric;
          // todo allow group definition in lines param
          const line = get(this.lines, column.metric, {});
          this.setLineBasedChartParams(line, chartData, id);
          metricCategoryForY = this.getAxisBasedCategory(line, metricCategoryForY);

          const values = column.data_points.map(point =>
            this.getYCategoryValue(point.ys, metricCategoryForY));
          const points = [column.metric, ...values];
          // push label into first col
          if (index === 0) {
            const labels = column.data_points.map(point => point.x);
            chartData.columns[0].push(...labels);
          }
          chartData.columns.push(points);
          chartData.meta[id] = column.data_points;

          if (chartData.axes[id] !== this.getPrimaryYAxis()) {
            this.showY2Axis = true;
          }

          return chartData;
        },
        {
          axes: {},
          colors: [],
          columns: [['labels']],
          meta: {},
          names: {},
          regions: {},
          types: {},
        },
      );
    },

    renderLegend() {
      if (!this.getShowLegend()) {
        return;
      }
      const { chart, selectedLines } = this;
      d3
        .select(this.$el.querySelector('.legend .select-lines'))
        .html(null)
        .selectAll('div')
        .data(chart.data().map(d => d.id))
        .enter()
        .append('div')
        .attr('class', 'legend-item')
        .classed('line-disabled', id => !selectedLines[id])
        .on('mouseover', (_, id) => {
          chart.focus(id);
        })
        .on('mouseout', () => {
          chart.revert();
        })
        .on('click', function (_, id) { // eslint-disable-line
          this.classList.toggle('line-disabled');
          selectedLines[id] = !selectedLines[id];
          chart.toggle(id);
        })
        .each(function (id) { // eslint-disable-line
          d3
            .select(this)
            .append('span')
            .style('background-color', chart.color(id))
            .style('border-color', chart.color(id))
            .attr('class', 'legend-item-icon');
          d3
            .select(this)
            .append('span')
            .attr('class', 'legend-item-label')
            .text(d => chart.internal.config.data_names[d]);
        });
    },
    colorChooser(colNumber) {
      const colorPalette = COLORS;
      const colors = Object.values(colorPalette);
      return colors[colNumber % colors.length];
    },
    renderTooltipContent(
      d,
      defaultTitleFormat,
      defaultValueFormat,
      color,
    ) {
      // Extracted and modified from C3js c3_chart_internal_fn.getTooltipContent
      // https://github.com/c3js/c3/blob/master/src/tooltip.js#L100

      const getYValues = src => {
        const metaLocal = this.chart.internal.config.data_classes[src.id];
        const { ys = [] } = metaLocal[src.index];
        return ys;
      };

      const $$ = this.chart.internal;
      const { config } = $$;
      const titleFormat = config.tooltip_format_title || defaultTitleFormat;
      const nameFormat =
        config.tooltip_format_name ||
        function (name) { // eslint-disable-line
          return name;
        };
      let text, // eslint-disable-line
          i,
          title,
          name,
          bgcolor;

      const tooltipSortFunction = $$.getTooltipSortFunction();
      if (tooltipSortFunction) {
        d.sort(tooltipSortFunction);
      }

      for (i = 0; i < d.length; i += 1) {
        if (!(d[i] && (d[i].value || d[i].value === 0))) {
          continue; // eslint-disable-line
        }

        if (!text) {
          title = sanitize(titleFormat ? titleFormat(d[i].x) : d[i].x);
          text = `<table class='${$$.CLASS.tooltip}'>${
            title || title === 0 ? `<tr><th colspan='2'>${title}</th></tr>` : ''
          }`;
        }

        // const values = getYValues(d[i]);
        // value = sanitize(valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index, d));
        const values = getYValues(d[i]).map(y => {
          const formatter =
            tooltipFormatters[y.metric_category] || tooltipFormatters.default;
          return formatter(y.value);
          // return sanitize(valueFormat(value, d[i].ratio, d[i].id, d[i].index, d))
        });
        if (values.length) {
          // Skip elements when their name is set to null
          if (d[i].name === null) {
            continue; // eslint-disable-line
          }
          name = sanitize(nameFormat(d[i].name, d[i].ratio, d[i].id, d[i].index));
          bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

          text += `<tr class='${
            $$.CLASS.tooltipName
          }-${$$.getTargetSelectorSuffix(d[i].id)}'>`;
          text += `<td class='name'><span style='background-color:${bgcolor}'></span>${name}</td>`;
          text += values.map(val => `<td class='value'>${val}</td>`).join('');
          text += '</tr>';
        }
      }

      return `${text}</table>`;
    },

    refreshLines() {
      const { chart } = this;
      if (!chart || !has(chart, 'show')) {
        // chart may come from external library
        // and may not have show or hide, example: funnel chart
        return;
      }
      this.$emit('onChartStatus', 'onrefresh');
      const ctx = this;
      // eslint-disable-next-line
      require(['d3', 'c3'], () => {
        Object.keys(ctx.selectedLines).forEach(key => {
          if (!chart) return;
          const method = ctx.selectedLines[key] ? chart.show : chart.hide;
          return method(key);
        });
        ctx.renderLegend();
      });
    },

    selectAllLines() {
      const linesSelected = this.allLinesSelected
        ? false
        : this.noLinesSelected || this.someLinesSelected;
      const newSelectionState = this.noLinesSelected ? true : linesSelected;

      this.selectedLines = setLinesSelectionState(
        this.lines,
        newSelectionState,
      );
    },

    onChartResized() {},

    onChartRendered() {
      this.$emit('onChartStatus', 'onrendered');
      if (this.showY2Axis) {
        this.$el.querySelector('.c3-axis-y2').style.fill = this.y2Color;
      }
    },
  },
};
</script>

<style lang='scss' >
/* todo make scoped style work */

/*-- Chart --*/
.chart-container svg {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  -webkit-tap-highlight-color: transparent;
}

.c3 path,
.c3 line {
  fill: none;
  stroke: #000;
}

.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}

.c3-chart-arc path {
  stroke: #fff;
}

.c3-chart-arc rect {
  stroke: white;
  stroke-width: 1;
}

.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa;
}

.c3-grid text {
  fill: #aaa;
}

.c3-xgrid,
.c3-ygrid {
  stroke-width: 1;
}

/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}

/*-- Line --*/
.c3-line {
  stroke-width: 0.5px;
}

/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white;
}

.c3-selected-circle {
  fill: white;
  stroke-width: 2px;
}

/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}

.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}

.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}

.c3-target.c3-defocused {
  opacity: 0.3 !important;
}

/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: 0.1;
}

/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: 0.1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {
  font-size: 12px;
}

.c3-legend-item-hidden {
  opacity: 0.15;
}

.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1;
}

/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}

.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  -webkit-box-shadow: 7px 7px 12px -9px #777777;
  -moz-box-shadow: 7px 7px 12px -9px #777777;
  box-shadow: 7px 7px 12px -9px #777777;
  opacity: 0.9;
}

.c3-tooltip tr {
  border: 1px solid #ccc;
}

.c3-tooltip th {
  background-color: #aaa;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left;
  color: #fff;
}

.c3-tooltip td {
  font-size: 13px;
  padding: 3px 6px;
  background-color: #fff;
  border-left: 1px dotted #999;
}

.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

.c3-tooltip td.value {
  text-align: right;
}

/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.2;
}

/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: #fff;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}

.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
  opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}

.c3-axis-y .tick line {
  display: none;
}

/* overrides */

.c3-line {
  stroke-width: 2px;
}

// grid lines
.c3-grid line {
  stroke: #bfcbd9;
}

//  x-axis
path.domain {
  stroke: #8492a6;
}

// x-axis tick
.c3-axis-x .tick line {
  // stroke: #152e53;
  // transform: translate(0, -6px);
}

// tooltip
.c3-tooltip-container {
  padding: 10px;

  .c3-tooltip {
    opacity: 1;
    background-color: #6488a6;
    border: 1px solid #6488a6;
    letter-spacing: 0.01em;
    box-shadow: none;

    tr {
      border: 0;

      &:not(:first-of-type) {
        margin-bottom: 10px;
      }
    }

    th {
      background-color: inherit;
      font-weight: initial;
      font-size: 12px;
      line-height: 1.2;
    }

    td {
      border-left: 0;
    }
  }
}

/*  page */
.chart-container {
  width: 100%;
  height: 320px;
}
.funnel {
  height: 40rem;
  width: 60rem;
}
.legend-container {
  display: flex;
  justify-content: center;

  .legend {
    display: flex;
    justify-content: center;
    font-size: 12px;

    > div {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .legend-item {
      display: flex;
      align-items: center;
      margin: 8px;
      cursor: pointer;

      &.line-disabled {
        .legend-item-icon {
          background-color: white !important;
        }

        .legend-item-label {
          text-decoration: line-through;
        }
      }

      .legend-item-icon {
        height: 10px;
        width: 34px;
        border: 3px solid;
        border-radius: 2px;
      }

      .legend-item-label {
        margin-left: 4px;
      }
    }
  }

  .el-checkbox__label {
    font-size: 12px;
  }
}
</style>
