import COLORS from './colors';

const conversationbyInteractionChartConfig = {
  name: 'conversations_by_interaction',
  title: 'Conversation Interaction',
  metric: 'conversations_by_interaction',
  downloadUsingLineLabels: true,
  graphParams: {
    metricCategoryForY: 'percent'
  },
  lines: {
    agentonly: {
      label: 'Agent',
      position: 1,
      color: COLORS.tangerine,
    },
    botonly: {
      label: 'Bot',
      position: 2,
      color: COLORS.steel_blue,
    },
    customeronly: {
      label: 'Customer',
      position: 3,
      color: COLORS.lavender,
    },
    agentsbots: {
      label: 'Agent + Bot',
      position: 4,
      color: COLORS.bougainvillea,
    },
    botcustomer: {
      label: 'Bot + Customer',
      position: 5,
      color: COLORS.blood_red,
    },
    agentscustomer: {
      label: 'Agent + Customer',
      position: 6,
      color: COLORS.grass,
    },
    agentsbotcustomer: {
      label: 'Agents +  Bot + Customer',
      position: 7,
      color: COLORS.orange,
    },
    nointeraction: {
      label: 'No interaction',
      position: 8,
      color: COLORS.celestial_blue,
    },
    total_convos: {
      label: 'Total',
      position: 9,
      type: 'scatter',
    }
  },
  helpText:
    'The Conversation Interaction graph shows all conversation interaction variations as a percentage of total conversations, totaling 100%. Hover over a bar to see detailed information for that date. Click export to export the data for the current view.',
};

export default conversationbyInteractionChartConfig;
