<template lang="pug">
aiq-tabs(@tab-click="onTabChange")
  aiq-tab-pane(v-for="tab in summaryTypeConfigs"
                :key="tab.report"
                :label="tab.label")
    .summary-table
      .summary-table-toolbar
        .flex-spacer
        FilteredInput.search(size="small"
                  v-model="query"
                  placeholder="Search")
        aiq-button.export(@click="exportTable")
          i.iq-ico-export

      summary-table(:dateRange="dateRange"
                    :breakdown="breakdown"
                    :report="selectedSummaryTypeConfig.report"
                    :items="filteredItems"
                    :selectedFilters="selectedFilters"
                    @onSelectionChange="onSelectionChange"
                    @onDataChange="onDataChange")
        slot
          aiq-table-column(label="Name", :sort-method="onSortName" min-width="170" :sortable="true" label-class-name="is-bordered")
            template(v-slot:default="scope")
              span {{ selectedSummaryTypeConfig.nameFormatter(scope.row) }} &nbsp;
          aiq-table-column(label="Conversations" width="200" prop="total_conversations" :sortable="true" label-class-name="is-bordered")
          aiq-table-column(label="Median Response Time" width="200" :sort-method="onSortMrt" :sortable="true" label-class-name="is-bordered")
            template(v-slot:default="scope")
              span(v-if="scope.row.median_first_response_time") {{ $filters.duration(scope.row.median_first_response_time) }} &nbsp;
          aiq-table-column(label="Median Survey Score" width="180" prop="median_rating_value" :sortable="true" label-class-name="is-bordered")
</template>

<script>
import get from 'lodash/get';
import SummaryTable from '../../../SummaryTable.vue';
import { downloadAsFile } from '@/libs';

export default {
  name: 'summaryMetricsSummaryTable',
  components: {
    SummaryTable,
  },
  props: {
    dateRange: {
      required: true,
      type: Array,
    },
    breakdown: {
      required: true,
      type: String,
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filterDisabled() {
      return !this.selection.length;
    },
    filteredItems() {
      if (!this.query) {
        return this.items;
      }

      const { nameFormatter } = this.selectedSummaryTypeConfig;
      const filter = d => nameFormatter(d).toLowerCase().includes(this.query.toLowerCase());
      return this.items.filter(filter);
    },
  },
  data() {
    const summaryTypeConfigs = [
      {
        report: 'metrics_by_agent',
        label: this.$t('metrics_tab.plaform_tab.summary_tab.tables_titles.agents'),
        filterKey: 'agents',
        nameFormatter: (d) => `${d.first_name || ''} ${d.last_name || ''}`.trim(),
        default: true,
      },
      {
        report: 'metrics_by_team',
        label: this.$t('metrics_tab.plaform_tab.summary_tab.tables_titles.teams'),
        filterKey: 'teams',
        nameFormatter: (d) => d.name,
      },
      {
        report: 'metrics_by_category',
        label: this.$t('metrics_tab.plaform_tab.summary_tab.tables_titles.categories'),
        filterKey: 'categories',
        nameFormatter: (d) => d.name,
      },
      {
        report: 'metrics_by_customer_segment',
        label: this.$t('metrics_tab.plaform_tab.summary_tab.tables_titles.segments'),
        filterKey: 'customer_segments',
        nameFormatter: (d) => d.name,
      },
      {
        report: 'metrics_by_tag',
        label: this.$t('metrics_tab.plaform_tab.summary_tab.tables_titles.tags'),
        filterKey: 'tags',
        nameFormatter: (d) => d.name,
      },
    ];
    const defaultSummaryTypeConfig = summaryTypeConfigs.find(d => d.default);
    return {
      summaryTypeConfigs,
      selectedSummaryTypeConfig: defaultSummaryTypeConfig,
      selectedSummaryTypeConfigKey: defaultSummaryTypeConfig.report,
      chartData: null,
      items: [],
      query: null,
      selection: [],
    };
  },
  methods: {
    onTabChange({index: tabIndex}) {
      this.query = null;
      this.selection = [];
      this.selectedSummaryTypeConfig = this.summaryTypeConfigs[tabIndex];
    },
    onDataChange(data = {}) {
      this.items = get(data, 'dataset.0.data_points', []);
    },
    onSelectionChange(selection = []) {
      if (!selection.length) {
        this.selection = [];
        return;
      }

      const { filterKey } = this.selectedSummaryTypeConfig;
      this.selection = [{
        [filterKey]: selection.map(d => d.id),
      }];
    },
    onSortName(a, b) {
      const { nameFormatter } = this.selectedSummaryTypeConfig;
      const valA = nameFormatter(a);
      const valB = nameFormatter(b);

      if (valA === valB) {
        return 0;
      }

      return valA < valB ? 1 : -1;
    },
    onSortMrt(a, b) {
      const valA = get(a, 'median_first_response_time', 0);
      const valB = get(b, 'median_first_response_time', 0);
      if (valA === valB) {
        return 0;
      }
      return valA < valB ? 1 : -1;
    },
    exportTable() {
      downloadAsFile({
        dataType: 'table',
        dataSource: 'metrics',
        data: this.items,
      });
    },
  },
};
</script>

<style lang="scss">
.summary-table-toolbar {
  .el-button.export {
    height: 30px;
    width: 40px;
    padding: 0;
    margin-left: 16px;

    &:after {
      clear: both;
    }
  }
}
</style>

<style lang="scss" scoped>
  .summary-table-toolbar {
    display: flex;

    .el-select {
      width: initial;
    }

    .flex-spacer {
      flex-grow: 1
    }

    .filter {
      margin-left: 16px;
    }

    .search {
      width: 264px;
    }
  }

  .content-inner {
    padding: 0;
  }

</style>
