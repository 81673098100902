<template lang="pug">
.queue-configuration
  .form-container
    .sub-header--solid {{$t('settings_tab.channels_tab.webchat_ui.queue_configuration')}}
    .form__inner-content
      .settings-container
        .header-config-container
          label {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.title') }}

      .settings-container
        aiq-form
          aiq-form-item
            label.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.targets') }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.targets_tooltip')")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-select(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.visibility" multiple placeholder="Select")
              aiq-option(v-for="item in channelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value")

          aiq-form-item(v-if="showQueueMessageField")
            label.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.define_queue_message') }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.define_queue_message_tooltip')")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-input(v-model="localWebchatConfigsStrings.features.show_queue_transparency.display_message" placeholder="Write message" size="small")
          
          aiq-form-item
            label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.segment_priority_queue') }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.segment_priority_queue_tooltip')")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-switch(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.segment_queue"
                        active-color="#30C28B"
                        inactive-color="#C0CCDA")
          
          aiq-form-item
            label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue') }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_tooltip')")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-switch(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue"
                        active-color="#30C28B"
                        inactive-color="#C0CCDA")
          
          aiq-form-item(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue", :class="{'is-error': v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$error }")
            label.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_prompt') }}
            aiq-input(v-model="localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt"
                      size="small"
                      name="queueTransparency.leave_queue_prompt")
            span.text-danger(v-if="v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$error") {{ v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$errors[0]?.$message }}
          
          aiq-form-item(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue", :class="{'is-error': v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$error}")
            label.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_workflow') }}
              aiq-tooltip(placement="top")
                template(v-slot:content)
                  div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_workflow_tooltip')")
                fa-icon.question-mark(:icon="['fas', 'circle-question']")
            aiq-select(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow" 
                      :placeholder="$t('global.select')" 
                      name="queueTransparency.leave_queue_workflow")
              aiq-option(v-for="item in workflows"
                        :key="item"
                        :label="item"
                        :value="item")
            span.text-danger(v-if="v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$error") {{ v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$errors[0]?.$message }}

      .settings-container
        .settings__item
          .save-buttons__container.settings__item
            aiq-button(:disabled="isQueueConfigChanged"
                      @click="resetConfig") {{$t('global.cancel')}}
            aiq-button.save__button(type="primary"
                                    :disabled="isQueueConfigChanged"
                                    @click="saveConfig") {{$t('global.save')}}
</template>

<script>
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { HAMBURGER_MENU_SELECTION_OPTIONS } from '@/constants/settings';

export default {
  name: 'QueueConfiguration',
  props: {
    webchatConfigs: {
      type: Object,
    },
    workflows: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['save'],
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      localWebchatConfigs: {
        features: {
          SHOW_QUEUE_TRANSPARENCY: {
            visibility: { $autoDirty: true },
            display_message: { $autoDirty: true },
            segment_queue: { $autoDirty: true },
            leave_queue: { $autoDirty: true },
            leave_queue_prompt: { $autoDirty: true },
            leave_queue_workflow:{ 
              requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue), 
              $autoDirty: true 
            },
          }
        }
      },
      localWebchatConfigsStrings: {
        features: {
          show_queue_transparency: {
            leave_queue_prompt: {
              requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue), 
              $autoDirty: true 
            }
          }
        }
      }
    };
  },
  data() {
    return {
      channelOptions: cloneDeep(HAMBURGER_MENU_SELECTION_OPTIONS),
      localWebchatConfigsStrings: {
        features: {
          show_queue_transparency: {
            display_message: '',
            leave_queue_prompt: '',
          },
        },
      },
      localWebchatConfigs: cloneDeep(this.webchatConfigs),
    };
  },
  watch: {
    webchatLanguageStrings: {
      handler(newVal) {
        this.localWebchatConfigsStrings = cloneDeep(get(newVal, 'webchat', {}));
      },
      deep: true,
    },
    webchatConfigs(newVal) {
      this.localWebchatConfigs = cloneDeep(newVal);
    },
  },
  computed: {
    ...mapState({
      webchatLanguageStrings: state => state.configs.language_strings,
    }),
    isQueueConfigChanged() {
      return isEqual(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY, this.webchatConfigs.features.SHOW_QUEUE_TRANSPARENCY) &&
          isEqual(this.localWebchatConfigsStrings.features.show_queue_transparency, get(this.webchatLanguageStrings, 'webchat.features.show_queue_transparency', {}));
    },
    showQueueMessageField() {
      return difference(this.channelOptions.map(x => x.value),
        this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.visibility).length !== 0;
    },
  },
  methods: {
    async saveConfig() {
      if (!(await this.v$.$validate())) {
        this.$aiq.notify.error(
          this.$t('settings_tab.channels_tab.webchat_configuration.unable_save_error'),
        );
        return;
      }
      this.$emit('save', {
        config: this.localWebchatConfigs,
        configStrings: this.localWebchatConfigsStrings,
      });
    },
    resetConfig() {
      this.localWebchatConfigs = cloneDeep(this.webchatConfigs);
      this.localWebchatConfigsStrings = cloneDeep(get(this.webchatLanguageStrings, 'webchat', {}));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

.form-container {
  flex: 1;
  margin-bottom: 20px;

  .form__inner-content {
    background: #FFFFFF;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    padding: 18px;

    * {
      margin: 5px 0px;
    }

    .settings-container {
      display: flex;

      .settings__item {
        align-self: flex-start;
        flex-grow: 1;
        min-width: 589px;
      }

      .el-form-item {
        margin-bottom: 15px;
        :deep(.el-form-item__content) {
          line-height: 15px !important;
          display: block;
          label {
            font-weight: 300;
          }
          span.text-danger {
            height: 0;
            margin-top: -5px;
            color: #F56C6C;
            font-size: 12px;
            display: block;
          }
          svg.question-mark {
            margin-left: 5px;
            margin-top: 0;
            margin-bottom: 0;
            &:hover {
              color: $aiq-bgc-header;
            }
            &:focus {
              outline: none;
            }
          }
          .inline-item {
            display: inline-flex;
            width: 300px !important;
          }
        }
      }
    }

    .save-buttons__container {
      background-color: #fff;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      left: 0;
      margin-bottom: 0px;
      padding: 5px 8px 0px 0px;
      width: calc(100% - 4px);

      * {
        margin: 0px 5px;
      }

      .save__button {
        margin-right: 0px;
      }
    }
  }
}
</style>
