<template lang="pug" id="funnel">
extends ./Base.pug
</template>

<script>
import c3 from 'c3';
import * as d3 from 'd3';
import moment from 'moment';
import D3Funnel from 'd3-funnel';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import BaseChart from './Base.vue';

const hoursPerDay = 24;
const daysPerWeek = 7;

export default {
  extends: BaseChart,
  name: 'aiqFunnelChart',
  props: {
    height: {
      type: Number,
      // todo: automatically set height
      default: () => 310, // eslint-disable-line
    },
    colors: {
      type: Array,
      default: () => ['#FFF2DB', '#FFE9C2', '#FFDB9C', '#FFCD75', '#FFBE50'],
    },
    showYGridLines: {
      type: Boolean,
      default: () => false,
    },
    yScaleMax: {
      type: Number,
      default: () => 0,
    },

    yScaleMin: {
      type: Number,
      default: () => 6, // eslint-disable-line
    },
    xTickValues: {
      type: Array,
      default: () => [...Array(hoursPerDay).keys()],
    },
    yTickValues: {
      type: Array,
      default: () => [...Array(daysPerWeek).keys()],
    },
    formatXTick: {
      type: Function,
      default: v => moment()
        .startOf('day')
        .add(v, 'hour')
        .format('ha'),
    },
    formatYTick: {
      type: Function,
      default: v => moment()
        .isoWeekday(v)
        .format('ddd'),
    },
    transitionDuration: {
      type: Number,
      default: () => c3.chart.internal.fn.getDefaultConfig().transition_duration,
    },
    displayValues: {
      type: String,
      default: () => 'max',
      validator: value => ['all', 'max', 'min'].includes(value),
    },
    lines: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.chart = new D3Funnel(this.$el.querySelector('.chart-container'));
    this.$nextTick(() => {
      window.addEventListener('resize', this.renderChart);
    });
  },
  willDestroy() {
    this.$nextTick(() => {
      window.removeEventListener('resize', this.chart.destroy);
    });
  },
  methods: {
    getChartType() {
      return 'funnelChart';
    },

    getScale() {
      const [metricKey] = Object.keys(this.lines);
      const metric = this.lines[metricKey];
      const { valueKey } = metric;
      const dataPoints = this.report.dataset.find(d => d.metric === metricKey)
        .data_points;
      const max = d3.max(dataPoints.map(d => d[valueKey]));
      const scaleSteps = Math.min(this.colors.length, max);
      const scaleBuckets = Array.from(Array(scaleSteps).keys()).map(d => d * (max / scaleSteps));
      return d3
        .scaleThreshold()
        .domain(scaleBuckets)
        .range(this.colors);
    },

    renderChart() {
      this.DestroyChart();
      const { dataset } = this.report;
      if (isEmpty(dataset)) {
        return;
      }
      this.chart.draw(this.setupConfig(cloneDeep(dataset)), this.graphParams);
    },
    setupConfig(dataset,  metricCategoryForY='count') {
      return dataset
        .filter(ds => this.lines[ds.metric])
        .map(ds => ({
          label: this.lines[ds.metric].funnelLabel,
          value: (get(ds, 'data_points') ? ds.data_points.reduce((acc, val) => acc + this.getYCategoryValue(val.ys,
            metricCategoryForY), 0) : 0),
        }));
    },
  },
};
</script>

<style lang="scss">
</style>
