const tagsProgressChartConfig = {
  title: 'Tag Usage',
  metric: 'tags_progress',
  helpText:
    'Displays tag use over time.',
  graphParams: {
    metricCategoryForY: 'tagsProgress',
    colColors: {},
  },
};

export default tagsProgressChartConfig;
