<template lang="pug">
  .onboarding
    .form-container
      .sub-header--solid {{$t('settings_tab.channels_tab.webchat_ui.onboarding_journal')}}
      .form__inner-content
        .settings-container
          .header-config-container
            .label {{$t('settings_tab.channels_tab.webchat_ui.select_target')}}
            .field-label
              .name {{$t('global.target')}}
                aiq-select(v-model="target" placeholder="Select")
                  aiq-option(v-for="val in targets"
                            :key="val"
                            :label="getTargetLabel(val)"
                            :value="val")

        .onboarding-set(v-if="target")
          .settings-container
            .header-config-container
              .field-label
                .name {{$t('settings_tab.channels_tab.webchat_ui.available_screens')}}
              .screen-container
                draggable(v-model="availableScreens" itemKey="value" group="screen")
                  template(v-slot:item="{element}")
                    aiq-tag(:key="element" :item="element" type="info") {{ getScreenLabel(element) }}

          .settings-container
            .header-config-container
              .field-label
                .name {{$t('settings_tab.channels_tab.webchat_ui.preauth_screens')}}
              .screen-container
                draggable(v-model="preAuthViews" itemKey="value" group="screen")
                  template(v-slot:item="{element}")
                    aiq-tag(:key="element" :item="element" type="info") {{ getScreenLabel(element) }}

          .settings-container
            .header-config-container
              .field-label
                .name {{$t('settings_tab.channels_tab.webchat_ui.postauth_screens')}}
              .screen-container
                draggable(v-model="postAuthViews" itemKey="value" group="screen")
                  template(v-slot:item="{element}")
                    aiq-tag(:key="element" :item="element" type="info") {{ getScreenLabel(element) }}

          .settings-container
            .settings__item
              .save-buttons__container.settings__item
                aiq-button(:disabled="isConfigSame"
                          @click="resetConfig") {{$t('global.cancel')}}
                aiq-button.save__button(type="primary"
                                        :disabled="isConfigSame"
                                        @click="saveConfig") {{$t('global.save')}}


</template>

<script>
import difference from 'lodash/difference';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { HAMBURGER_MENU_SELECTION_OPTIONS, ONBOARD_SCREERNS } from '@/constants/settings';

export default {
  name: 'OnboardingJourney',
  components: {
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
    targets: {
      type: Array,
      default: [],
    },
    preOnboardingConfig: {
      type: Object,
      default: null,
    },
    postOnboardingConfig: {
      type: Object,
      default: null,
    },
  },
  emits: ['change', 'update'],
  data() {
    return {
      target: '',
      preAuthViews: [],
      postAuthViews: [],
      originalPreAuthViews: [],
      originalPostAuthViews: [],
    };
  },
  watch: {
    target(newVal) {
      this.$emit('change', newVal);
      return newVal;
    },
    preOnboardingConfig(newVal) {
      this.originalPreAuthViews = get(newVal, 'views', []).map(item => item.name);
      this.preAuthViews = cloneDeep(this.originalPreAuthViews);
    },
    postOnboardingConfig(newVal) {
      this.originalPostAuthViews = get(newVal, 'views', []).map(item => item.name);
      this.postAuthViews = cloneDeep(this.originalPostAuthViews);
    },
  },
  computed: {
    availableScreens: {
      get() {
        return difference(difference(this.items, this.preAuthViews), this.postAuthViews);
      },
      set(value) {
        return value;
      },
    },
    isConfigSame() {
      return isEqual(this.originalPreAuthViews, this.preAuthViews)
          && isEqual(this.originalPostAuthViews, this.postAuthViews);
    },
  },
  methods: {
    saveConfig() {
      const preOnboardingConfig = cloneDeep(this.preOnboardingConfig);
      const postOnboardingConfig = cloneDeep(this.postOnboardingConfig);

      preOnboardingConfig.views = this.preAuthViews.map(name => ({ name }));
      postOnboardingConfig.views = this.postAuthViews.map(name => ({ name }));

      this.$emit('update', {
        target: this.target,
        preOnboardingConfig,
        postOnboardingConfig,
      });
    },
    resetConfig() {
      this.preAuthViews = cloneDeep(this.originalPreAuthViews);
      this.postAuthViews = cloneDeep(this.originalPostAuthViews);
    },
    getTargetLabel(val) {
      return get(HAMBURGER_MENU_SELECTION_OPTIONS.find(item => item.value === val), 'label', '');
    },
    getScreenLabel(val) {
      return get(ONBOARD_SCREERNS.find(item => item.value === val), 'label', '');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

.form-container {
  flex: 1;
  margin-bottom: 20px;

  .form__inner-content {
    background: #FFFFFF;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    padding: 18px;

    * {
      margin: 5px 0px;
    }

    .delete-font {
      font-size: 10px;
      color: red;
      margin-left: 10px;
    }

    .no-margin {
      padding-right: 10px;
      margin: 0px;
    }

    .settings-container {
      display: flex;

      .settings__item {
        align-self: flex-start;
        flex-grow: 1;
        min-width: 589px;
      }
    }


    .field-label {
      color: #475669;
      letter-spacing: 0;
      font-family: $aiq-font-family;
      font-size: 14px;
      font-weight: 300;
      display: flex;
      justify-content: space-between;
    }

    .header-config-container {
      width: 482px;
    }

    .save-buttons__container {
      background-color: #fff;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      left: 0;
      margin-bottom: 0px;
      padding: 5px 8px 0px 0px;
      width: calc(100% - 4px);

      * {
        margin: 0px 5px;
      }

      .save__button {
        margin-right: 0px;
      }
    }

    .name .el-select {
      min-width: 212px;
    }
  }

}

.small-font {
  font-size: 12px;
}

.inline-label-box {
  display: flex;

  .inline-item {
    width: 150px;
  }
}

.image {
  height: 50px;
}

.pointer {
  cursor: pointer;
}

.upload-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

</style>
