<template lang="pug">
.segment-select-container(:style="containerStyle")
  aiq-tooltip(content="Filter by Segments" placement="top")
    aiq-select(placeholder="Filter by Segments"
              v-model="selectedOptions"
              :multiple="isSelectMultiple"
              :collapse-tags="isSelectCollapseTags"
              :suffix-icon="Icon.CaretBottom"
              @change="onCustomerSegmentFilterChange($event)"
              value-key='id'
              size="small"
              :reserve-keyword="false")
      aiq-option(v-for="option in customerSegmentFilterOptions"
                :key="option.id"
                :label="limitedString(option.name)"
                :value="option"
                @mouseenter="onMouseEnter(option)"
                @mouseleave="onMouseLeave")
  aiq-tooltip(:content="segmentAliasContent"
              :placement="tooltipPlacement"
              :visible="showTooltip"
              :show-arrow="false"
              :show-after="500")
    div
</template>

<script>
import { limitedString } from '@/libs/stringUtils';

export default {
  props: {
    customerSegmentFilterOptions: {
      type: Array,
      default: [],
    },
    initalSelection: {
      type: [String, Array],
      default: null,
    },
    segmentAlias: {
      type: String,
      default: '',
    },
    containerStyle: {
      type: Object,
      default: {},
    },
    isSelectMultiple: {
      type: Boolean,
      default: false,
    },
    isSelectCollapseTags: {
      type: Boolean,
      default: false,
    },
    tooltipPlacement: {
      type: String,
      default: 'right',
    },
  },
  emits: ['change', 'on-segment-hover'],
  data() {
    return {
      selectedOptions: this.isSelectMultiple ? [] : '',
      showTooltip: false,
      segmentAliasContent: '',
    };
  },
  mounted() {
    if (this.initalSelection) {
      this.selectedOptions = this.initalSelection;
    }
  },
  methods: {
    onCustomerSegmentFilterChange(selectedSegments) {
      this.$emit('change', selectedSegments);
    },
    onMouseEnter(segment) {
      this.$emit('on-segment-hover', segment);
    },
    onMouseLeave() {
      this.showTooltip = false;
      this.$nextTick(() => {
        this.$emit('on-segment-hover', '');
      });
    },
    limitedString,
  },
  watch: {
    segmentAlias(newValue, oldValue) {
      if (!newValue) {
        this.segmentAliasContent = '';
      } else if (newValue !== oldValue) {
        this.segmentAliasContent = `${this.$t('global.segment_description')}: ${newValue}`;
        this.$nextTick(() => {
          this.showTooltip = true;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.segment-select-container {
  display: flex;
  width: 100%;
}
</style>
